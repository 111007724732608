import { ArrowBackIosRounded, Refresh } from '@mui/icons-material'
import { Divider, FormControlLabel, Switch, TextField } from '@mui/material'
import { fetchLineage } from '../../store/actionCreators'
import { THEME_EXTRA, theme } from '../../helpers/theme'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import MQTooltip from '../../components/core/tooltip/MQTooltip'
import MqText from '../../components/core/text/MqText'
import React from 'react'

interface ActionBarProps {
  nodeType: 'DATASET' | 'JOB'
  fetchLineage: typeof fetchLineage
  depth: number
  setDepth: (depth: number) => void
  isCompact: boolean
  setIsCompact: (isCompact: boolean) => void
  isFull: boolean
  setIsFull: (isFull: boolean) => void
}

export const ActionBar = ({
  nodeType,
  fetchLineage,
  depth,
  setDepth,
  isCompact,
  setIsCompact,
  isFull,
  setIsFull,
}: ActionBarProps) => {
  const { namespace, name } = useParams()
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  return (
    <Box
      sx={{
        borderBottomWidth: 1,
        borderTopWidth: 0,
        borderLeftWidth: 0,
        borderRightWidth: 0,
        borderStyle: 'solid',
      }}
      display={'flex'}
      height={'64px'}
      justifyContent={'space-between'}
      alignItems={'center'}
      px={2}
      borderColor={THEME_EXTRA.border.tableHeader}
    >
      <Box display={'flex'} alignItems={'center'}>
        <MQTooltip title={`Back to ${nodeType === 'JOB' ? 'jobs' : 'datasets'}`}>
          <IconButton
            size={'small'}
            sx={{ mr: 2, color: theme.palette.primary.main }}
            onClick={() => navigate(nodeType === 'JOB' ? '/' : '/datasets')}
          >
            <ArrowBackIosRounded fontSize={'small'} />
          </IconButton>
        </MQTooltip>
        <MqText heading>{nodeType === 'JOB' ? 'Jobs' : 'Datasets'}</MqText>
        <Divider orientation='vertical' flexItem sx={{ mx: 2 }} />
        <Box>
          <MqText subheading>Mode</MqText>
          <MqText>Table Level</MqText>
        </Box>
        <Divider orientation='vertical' flexItem sx={{ mx: 2 }} />
        <Box>
          <MqText subheading>Workspace</MqText>
          <MqText>{namespace || 'Unknown workspace name'}</MqText>
        </Box>
        <Divider orientation='vertical' flexItem sx={{ mx: 2 }} />
        <Box>
          <MqText subheading>Name</MqText>
          <MqText>{name || 'Unknown dataset name'}</MqText>
        </Box>
      </Box>
      <Box display={'flex'} alignItems={'center'}>
        <MQTooltip title={'Refresh'}>
          <IconButton
            sx={{ mr: 2 }}
            color={'primary'}
            size={'small'}
            onClick={() => {
              if (namespace && name) {
                fetchLineage(nodeType, namespace, name, depth)
              }
            }}
          >
            <Refresh fontSize={'small'} />
          </IconButton>
        </MQTooltip>
        <TextField
          id='column-level-depth'
          type='number'
          label='Depth'
          variant='outlined'
          size='small'
          sx={{ width: '80px', mr: 2 }}
          value={depth}
          InputProps={{ style: { color: theme.palette.common.black } }}
          InputLabelProps={{ style: { color: theme.palette.primary.main } }}
          onChange={(e) => {
            setDepth(isNaN(parseInt(e.target.value)) ? 0 : parseInt(e.target.value))
            searchParams.set('depth', e.target.value)
            setSearchParams(searchParams)
          }}
        />
        <Box display={'flex'} flexDirection={'column'}>
          <FormControlLabel
            control={
              <Switch
                size={'small'}
                value={isFull}
                defaultChecked={searchParams.get('isFull') === 'true'}
                onChange={(_, checked) => {
                  setIsFull(checked)
                  searchParams.set('isFull', checked.toString())
                  setSearchParams(searchParams)
                }}
                sx={{
                  '& .MuiSwitch-track': { backgroundColor: THEME_EXTRA.typography.headerMenuDark },
                  // '& .MuiSwitch-thumb': { backgroundColor: THEME_EXTRA.typography.subdued },
                }}
              />
            }
            label={<MqText>Full Graph</MqText>}
          />
          <FormControlLabel
            control={
              <Switch
                size={'small'}
                value={isCompact}
                defaultChecked={searchParams.get('isCompact') === 'true'}
                onChange={(_, checked) => {
                  setIsCompact(checked)
                  searchParams.set('isCompact', checked.toString())
                  setSearchParams(searchParams)
                }}
                sx={{
                  '& .MuiSwitch-track': { backgroundColor: THEME_EXTRA.typography.headerMenuDark },
                  // '& .MuiSwitch-thumb': { backgroundColor: THEME_EXTRA.typography.subdued },
                }}
              />
            }
            label={<MqText>Compact Nodes</MqText>}
          />
        </Box>
      </Box>
    </Box>
  )
}
