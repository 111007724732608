// Copyright 2018-2023 contributors to the Marquez project
// SPDX-License-Identifier: Apache-2.0

import * as Redux from 'redux'
import {
  Chip,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  createTheme,
} from '@mui/material'
import { Dataset } from '../../types/api'
import { HEADER_HEIGHT, THEME_EXTRA } from '../../helpers/theme'
import { IState } from '../../store/reducers'
import { MqScreenLoad } from '../../components/core/screen-load/MqScreenLoad'
import { Nullable } from '../../types/util/Nullable'
import { Refresh } from '@mui/icons-material'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
  datasetFacetsQualityAssertions,
  datasetFacetsStatus,
  encodeNode,
} from '../../helpers/nodes'
import { fetchDatasets, resetDatasets } from '../../store/actionCreators'
import { formatUpdatedAt } from '../../helpers'
import { useTheme } from '@emotion/react'
import Assertions from '../../components/datasets/Assertions'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress/CircularProgress'
import IconButton from '@mui/material/IconButton'
import MQTooltip from '../../components/core/tooltip/MQTooltip'
import MqEmpty from '../../components/core/empty/MqEmpty'
import MqPaging from '../../components/paging/MqPaging'
import MqStatus from '../../components/core/status/MqStatus'
import MqText from '../../components/core/text/MqText'
import NamespaceSelect from '../../components/namespace-select/NamespaceSelect'
import React from 'react'
import {
  DISABLED_BUTTON,
  TABLE_CONTAINER,
  TABLE_CELL,
  TABLE_HEADER,
  TABLE_HEADER_BORDER,
} from '../../helpers/commonStyles'

interface StateProps {
  datasets: Dataset[]
  isDatasetsLoading: boolean
  isDatasetsInit: boolean
  selectedNamespace: Nullable<string>
  totalCount: number
}

interface DatasetsState {
  page: number
}

interface DispatchProps {
  fetchDatasets: typeof fetchDatasets
  resetDatasets: typeof resetDatasets
}

type DatasetsProps = StateProps & DispatchProps

const PAGE_SIZE = 20
const DATASET_HEADER_HEIGHT = 64

const Datasets: React.FC<DatasetsProps> = ({
  datasets,
  totalCount,
  isDatasetsLoading,
  isDatasetsInit,
  selectedNamespace,
  fetchDatasets,
  resetDatasets,
}) => {
  const defaultState = {
    page: 0,
  }
  const [state, setState] = React.useState<DatasetsState>(defaultState)

  const theme = createTheme(useTheme())

  const styleObject = {
    ml2: { marginLeft: theme.spacing(2) },
    dark: { color: theme.palette.common.black },
  }

  React.useEffect(() => {
    if (selectedNamespace) {
      fetchDatasets(selectedNamespace, PAGE_SIZE, state.page * PAGE_SIZE)
    }
  }, [selectedNamespace, state.page])

  React.useEffect(() => {
    return () => {
      // on unmount
      resetDatasets()
    }
  }, [])

  const handleClickPage = (direction: 'prev' | 'next') => {
    const directionPage = direction === 'next' ? state.page + 1 : state.page - 1

    fetchDatasets(selectedNamespace || '', PAGE_SIZE, directionPage * PAGE_SIZE)
    // reset page scroll
    window.scrollTo(0, 0)
    setState({ ...state, page: directionPage })
  }

  const i18next = require('i18next')
  return (
    <Container
      maxWidth={'lg'}
      disableGutters
      sx={{ maxWidth: '100% !important', padding: '0 2.5rem 0 2.5rem' }}
    >
      {/* <Box p={2} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
        <Box display={'flex'}>
          <MqText heading>{i18next.t('datasets_route.heading')}</MqText>
          {!isDatasetsLoading && (
            <Chip
              size={'small'}
              variant={'outlined'}
              color={'primary'}
              sx={{ marginLeft: 1 }}
              label={totalCount + ' total'}
            ></Chip>
          )}
        </Box>
        <Box display={'flex'} alignItems={'center'}>
          {isDatasetsLoading && <CircularProgress size={16} />}
          <NamespaceSelect />
          <MQTooltip title={'Refresh'}>
            <IconButton
              sx={{ ml: 2 }}
              color={'primary'}
              size={'small'}
              onClick={() => {
                if (selectedNamespace) {
                  fetchDatasets(selectedNamespace, PAGE_SIZE, state.page * PAGE_SIZE)
                }
              }}
            >
              <Refresh fontSize={'small'} />
            </IconButton>
          </MQTooltip>
        </Box>
      </Box> */}
      <MqScreenLoad
        loading={isDatasetsLoading && !isDatasetsInit}
        customHeight={`calc(100vh - ${HEADER_HEIGHT}px - ${DATASET_HEADER_HEIGHT}px)`}
      >
        <>
          {datasets.length === 0 ? (
            <Box p={2}>
              <MqEmpty title={i18next.t('datasets_route.empty_title')}>
                <MqText dark>{i18next.t('datasets_route.empty_body')}</MqText>
              </MqEmpty>
            </Box>
          ) : (
            <>
              <TableContainer sx={TABLE_CONTAINER}>
                <Table size='small'>
                  <TableHead sx={TABLE_HEADER}>
                    <TableRow>
                      <TableCell
                        key={i18next.t('datasets_route.name_col')}
                        align='left'
                        sx={TABLE_HEADER_BORDER}
                      >
                        <MqText subheading>{i18next.t('datasets_route.name_col')}</MqText>
                      </TableCell>
                      <TableCell
                        key={i18next.t('datasets_route.namespace_col')}
                        align='left'
                        sx={TABLE_HEADER_BORDER}
                      >
                        <MqText subheading>{i18next.t('datasets_route.namespace_col')}</MqText>
                      </TableCell>
                      <TableCell
                        key={i18next.t('datasets_route.source_col')}
                        align='left'
                        sx={TABLE_HEADER_BORDER}
                      >
                        <MqText subheading>{i18next.t('datasets_route.source_col')}</MqText>
                      </TableCell>
                      <TableCell
                        key={i18next.t('datasets_route.updated_col')}
                        align='left'
                        sx={TABLE_HEADER_BORDER}
                      >
                        <MqText subheading>{i18next.t('datasets_route.updated_col')}</MqText>
                      </TableCell>
                      {/* <TableCell
                        key={i18next.t('datasets_route.quality')}
                        align='left'
                        sx={TABLE_HEADER_BORDER}
                      >
                        <MqText subheading>{i18next.t('datasets_route.quality')}</MqText>
                      </TableCell> */}
                      <TableCell
                        key={i18next.t('datasets.column_lineage_tab')}
                        align='left'
                        sx={TABLE_HEADER_BORDER}
                      >
                        <MqText inline subheading>
                          Column Lineage
                        </MqText>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {datasets
                      .filter((dataset) => !dataset.deleted)
                      .map((dataset) => {
                        const assertions = datasetFacetsQualityAssertions(dataset.facets)
                        return (
                          <TableRow key={dataset.name}>
                            <TableCell align='left' sx={TABLE_CELL}>
                              <MqText
                                link
                                bold
                                linkTo={`/lineage/${encodeNode(
                                  'DATASET',
                                  dataset.namespace,
                                  dataset.name
                                )}`}
                              >
                                {dataset.name}
                              </MqText>
                            </TableCell>
                            <TableCell align='left' sx={TABLE_CELL}>
                              <MqText>{dataset.namespace}</MqText>
                            </TableCell>
                            <TableCell align='left' sx={TABLE_CELL}>
                              <MqText>{dataset.sourceName}</MqText>
                            </TableCell>
                            <TableCell align='left' sx={TABLE_CELL}>
                              <MqText>{formatUpdatedAt(dataset.updatedAt)}</MqText>
                            </TableCell>
                            {/* <TableCell align='left' sx={TABLE_CELL}>
                              {datasetFacetsStatus(dataset.facets) ? (
                                <>
                                  <MQTooltip title={<Assertions assertions={assertions} />}>
                                    <Box>
                                      <MqStatus
                                        label={
                                          assertions.find((a) => !a.success)
                                            ? 'UNHEALTHY'
                                            : 'HEALTHY'
                                        }
                                        color={datasetFacetsStatus(dataset.facets)}
                                      />
                                    </Box>
                                  </MQTooltip>
                                </>
                              ) : (
                                <MqStatus label={'N/A'} color={theme.palette.secondary.main} />
                              )}
                            </TableCell> */}
                            <TableCell sx={TABLE_CELL}>
                              {dataset.columnLineage ? (
                                <MqText
                                  link
                                  bold
                                  linkTo={`column-level/${encodeURIComponent(
                                    encodeURIComponent(dataset.id.namespace)
                                  )}/${encodeURIComponent(dataset.id.name)}`}
                                >
                                  VIEW
                                </MqText>
                              ) : (
                                <MqText subdued>N/A</MqText>
                              )}
                            </TableCell>
                          </TableRow>
                        )
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              <MqPaging
                pageSize={PAGE_SIZE}
                currentPage={state.page}
                totalCount={totalCount}
                incrementPage={() => handleClickPage('next')}
                decrementPage={() => handleClickPage('prev')}
              />
            </>
          )}
        </>
      </MqScreenLoad>
    </Container>
  )
}

const mapStateToProps = (state: IState) => ({
  datasets: state.datasets.result,
  totalCount: state.datasets.totalCount,
  isDatasetsLoading: state.datasets.isLoading,
  isDatasetsInit: state.datasets.init,
  selectedNamespace: state.namespaces.selectedNamespace,
})

const mapDispatchToProps = (dispatch: Redux.Dispatch) =>
  bindActionCreators(
    {
      fetchDatasets: fetchDatasets,
      resetDatasets: resetDatasets,
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(Datasets)
